import {
  DIVIDEND_FEED,
  EMAIL_IMAGE_GENERATOR,
  GENERAL,
  IMAGE_GENERATOR,
  LEGAL_DOCUMENTS,
  TRADING_HOURS,
  UPLOAD,
  YT_THUMBNAIL_CREATOR,
} from "../routes/routes";

export const NavigationObj = () => {
  const headerItems = [
    {
      name: "Creative Tools",
      subNav: [
        {
          name: "Social Image Creator ",
          href: `/${IMAGE_GENERATOR}/Analysis-Articles`,
        },
        { name: "Email Image Creator ", href: `/${EMAIL_IMAGE_GENERATOR}` },
        {
          name: "Youtube Thumbnail Creator ",
          href: `/${YT_THUMBNAIL_CREATOR}`,
        },
      ],
    },
    {
      name: "Changelog",
      href: "https://changelog.deanweaver.com.au",
    },
    {
      name: "File Uploader",
      // href: `/${UPLOAD}`,
      subNav: [
        {
          name: "Dashboard",
          href: `/${UPLOAD}`,
        },
        {
          name: "General",
          href: `/${UPLOAD}/${GENERAL}`,
        },
        { name: "Dividend Feed", href: `/${UPLOAD}/${DIVIDEND_FEED}` },
        {
          name: "Trading Hours",
          href: `/${UPLOAD}/${TRADING_HOURS}`,
        },
        {
          name: "Legal Documents",
          href: `/${UPLOAD}/${LEGAL_DOCUMENTS}`,
        },
      ],
    },
  ];

  return headerItems;
};

export const FooterItems = () => {
  const footerItems = [
    {
      title: "SITE MAP",
      contents: [
        {
          name: "Social Image Creator",
          href: `/${IMAGE_GENERATOR}/Analysis-Articles`,
        },
        {
          name: "Email Image Creator",
          href: `/${EMAIL_IMAGE_GENERATOR}`,
        },
        {
          name: "Youtube Image Creator",
          href: `/${YT_THUMBNAIL_CREATOR}`,
        },
        {
          name: "Changelog",
          href: "https://changelog.deanweaver.com.au",
        },
      ],
    },
    {
      title: "MORE RESOURCES",
      contents: [
        {
          name: "Branding Guidelines",
          href: "https://gomarkets.atlassian.net/wiki/spaces/BRAN/overview",
        },
        {
          name: "Logo Packs",
          href: "https://lp.gomarkets.com/brand/GO-Markets-Brand-Guidelines.pdf",
        },
        {
          name: "Asana Board",
          href: "https://app.asana.com/0/1200212572459710/board",
        },
        {
          name: "Confluence",
          href: "https://gomarkets.atlassian.net/wiki/home",
        },
      ],
    },
  ];

  return footerItems;
};
