import { useEffect, useRef, useState } from "react";

export const ImageConvertorStyle = () => {
  const style = document.createElement("style");
  document.head.appendChild(style);
  style.sheet?.insertRule(
    "body > div:last-child img { display: inline-block; }"
  );

  return style;
};

export const TypeWriter = (text) => {
  const index = useRef(0);

  const [currentText, setCurrentText] = useState("");

  useEffect(() => {
    index.current = 0;
    setCurrentText("");
  }, [text]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCurrentText(currentText + text.charAt(index.current));
      index.current += 1;
    }, 100);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [currentText, text]);

  return { currentText, index };
};

export const LanguageFont = (lang) => {
  const fontStyle =
    lang === "en" || lang === ""
      ? "!font-goma"
      : lang === "th"
      ? "!font-th"
      : lang === "zh-CN" || lang === "zh-TW"
      ? "!font-zh"
      : lang === "vi"
      ? "!font-body"
      : "!font-inter";

  const btmFontStyle =
    lang === "en" || lang === ""
      ? "!font-inter"
      : lang === "th"
      ? "!font-th"
      : lang === "zh-CN" || lang === "zh-TW"
      ? "!font-zh"
      : lang === "vi"
      ? "!font-body"
      : "!font-inter";

  return { fontStyle, btmFontStyle };
};

export const Sort = (data, filter) => {
  const arr = [];

  if (data) {
    Object.values(data).map((value) => {
      return arr.push(value);
    });
  }

  var sortedData = arr.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  var filtered = sortedData.filter(
    (user) => user.user_id === localStorage.userEmail
  );

  return filter === "userUploaded" ? filtered : sortedData;
};

export const RemoveFileExt = (fileName) => {
  return fileName.replace(/\.[^/.]+$/, "");
};
