import { Caution, DevConsole, Edit, Envalope, Pen } from "../layouts/icons";
import {
  EMAIL_IMAGE_GENERATOR,
  IMAGE_GENERATOR,
  YT_THUMBNAIL_CREATOR,
} from "../routes/routes";

// Request forms
export const AsanaForms = () => {
  const asanaForms = [
    {
      title: "Design Request",
      description: "Branding, banner ads, animations and website UI",
      url: "https://form.asana.com/?k=cHu_sTWbqExko3gCHAz0pA&d=555449702398463",
      icon: <Pen />,
      color: "hover:text-primary hover:border-primary",
    },
    {
      title: "Development Request",
      description: "New webiste features and products",
      url: "https://form.asana.com/?k=QGYQxk0vJzl5Lj_pRPQKFg&d=555449702398463",
      icon: <DevConsole />,
      color: "hover:text-customBlue hover:border-customBlue",
    },
    {
      title: "Email Request",
      description: "Campaigns, client journeys and communications",
      url: "https://form.asana.com/?k=OPJY1EwSMqkbZFq-yzOIlA&d=555449702398463",
      icon: <Envalope />,
      color: "hover:text-customPurple hover:border-customPurple",
    },
    {
      title: "Bug Fix",
      description: "Website technical bugs",
      url: "https://form.asana.com/?k=Uu_iK8X1tJ3Zsn1GTqrohQ&d=555449702398463",
      icon: <Caution />,
      color: "hover:text-customRed hover:border-customRed",
    },
    {
      title: "Content Request",
      description: "Add new, edit existing or remove website content",
      url: "https://form.asana.com/?k=6WBy5JiSQwZoq3eyOW6Dtg&d=555449702398463",
      icon: <Edit />,
      color: "hover:text-customYellow hover:border-customYellow",
    },
  ];

  return asanaForms;
};

//Home page grid
export const GridItems = () => {
  const gridItems = [
    {
      category: "1",
      items: [
        {
          title: "Logo Packs",
          url: "https://gomarkets.atlassian.net/wiki/spaces/BRAN/pages/1510637569/GO+Logo+Design+System",
          image: "/resources/tileImg3.png",
          priority: "high",
          info: "Create supporting imagery for your news and analysis posts, market reactions or events.",
        },

        {
          title: "Email Marketing inspiration ",
          url: "https://reallygoodemails.com/",
          image: "/resources/email-inspiration-tile.jpg",
          priority: "high",
          info: "Create supporting imagery for your news and analysis posts, market reactions or events.",
        },
      ],
    },

    {
      category: "2",
      items: [
        {
          title: "Branded Templates",
          url: "https://gomarkets.atlassian.net/wiki/spaces/BRAN/overview#%F0%9F%93%84-GO-Branded-Document-Templates",
          image: "/resources/tileImg2.png",
          priority: "med",
          info: "Create supporting imagery for your news and analysis posts, market reactions or events.",
        },
        {
          title: "Difference Between Development, Staging and Production",
          url: "https://dev.to/flippedcoding/difference-between-development-stage-and-production-d0p",
          image: "/resources/tileImg.png",
          priority: "high",
          info: "Create supporting imagery for your news and analysis posts, market reactions or events.",
        },
      ],
    },

    {
      category: "3",
      items: [
        {
          title: "Best stock image libraries",
          url: "https://zapier.com/blog/best-free-stock-photos/",
          image: "/resources/stock-resources-tile.jpg",
          priority: "low",
          info: "Create supporting imagery for your news and analysis posts, market reactions or events.",
        },
        {
          title: "15 High converting landing pages",
          url: "https://unbounce.com/landing-page-examples/high-converting-landing-pages/",
          image: "/resources/landing-pages-tile.jpg",
          priority: "high",
          info: "Create supporting imagery for your news and analysis posts, market reactions or events.",
        },
        {
          title: "How to resize your key visual",
          url: "https://squoosh.app/",
          image: "/resources/sqoosh-tile.jpg",
          priority: "med",
          info: "Create supporting imagery for your news and analysis posts, market reactions or events.",
        },
      ],
    },
    {
      category: "4",
      items: [
        {
          title: "UI vs UX - whats the difference?",
          url: "https://www.usertesting.com/resources/topics/ui-vs-ux",
          image: "/resources/ui-v-ux-tile.jpg",
          priority: "high",
          info: "Create supporting imagery for your news and analysis posts, market reactions or events.",
        },
        {
          title: "Marketing trends in 2023",
          url: "https://asana.com/resources/marketing-trends",
          image: "/resources/marketing-trends-tile.jpg",
          priority: "high",
          info: "Create supporting imagery for your news and analysis posts, market reactions or events.",
        },
      ],
    },
  ];
  return gridItems;
};

//Hope page tabs
export const TabItems = () => {
  const tabItems = [
    {
      title: "Discover",
      content: [
        {
          category: "Social",
          cardTitle: "Analysis & Articles",
          cardContent:
            "Create supporting imagery for your news and analysis posts, market reactions or events.",
          image: "/news-anaylsis-tile.jpg",
          path: `/${IMAGE_GENERATOR}/Analysis-Articles`,
          template: "Analysis-Articles",
          new: false,
        },
        {
          category: "Email",
          cardTitle: "Email Header ",
          cardContent: "Create custom image headers for your next send.",
          image: "/email-img-tile.jpg",
          path: `/${EMAIL_IMAGE_GENERATOR}`,
          template: "",
          new: false,
        },
        {
          category: "Social",
          cardTitle: "Webinars and Seminars",
          cardContent:
            "Promote your ongoing webinar sessions and seminars with event information or speakers.",
          image: "/webinar-tile-preview.png",
          path: `/${IMAGE_GENERATOR}/Webinar-Seminars`,
          template: "Webinar-Seminars",
          new: false,
        },
        {
          category: "Youtube",
          cardTitle: "Youtube Thumbnails",
          cardContent:
            "Draw attention to your latest video posts and brand your playlists.",
          image: "/youtube-thumb-preview.jpg",
          path: `/${YT_THUMBNAIL_CREATOR}`,
          template: "",
          new: false,
        },
        {
          category: "Social",
          cardTitle: "Campaign Template",
          cardContent:
            "Create global campaign creatives across a number of regions and languages.",
          image: "/global/campaign-tile-preview.jpg",
          path: `/${IMAGE_GENERATOR}/Global-Campaigns`,
          template: "Global-Campaigns",
          new: false,
        },
        {
          category: "Social",
          cardTitle: "Market Movers",
          cardContent: "React to key market price moves as they happen.",
          image: "/920x613-tile-market-movers.jpg",
          path: `/${IMAGE_GENERATOR}/Market-Movers`,
          template: "",
          new: false,
        },
      ],
    },
    {
      title: "Email",
      content: [
        {
          category: "Email",
          cardTitle: "Email Header ",
          cardContent: "Create custom image headers for your next send.",
          image: "/email-img-tile.jpg",
          path: `/${EMAIL_IMAGE_GENERATOR}`,
          new: false,
        },
      ],
    },

    {
      title: "Youtube",
      content: [
        {
          category: "Youtube",
          cardTitle: "Youtube Thumbnails",
          cardContent:
            "Draw attention to your latest video posts and brand your playlists.",
          image: "/youtube-thumb-preview.jpg",
          path: `/${YT_THUMBNAIL_CREATOR}`,
          template: "",
          new: false,
        },
      ],
    },
    {
      title: "Social",
      content: [
        {
          category: "Social",
          cardTitle: "Analysis & Articles",
          cardContent:
            "Create supporting imagery for your news and analysis posts, market reactions or events.",
          image: "/news-anaylsis-tile.jpg",
          path: `/${IMAGE_GENERATOR}/Analysis-Articles`,
          template: "Analysis-Articles",
          new: false,
        },
        {
          category: "Social",
          cardTitle: "Webinars and Seminars",
          cardContent:
            "Promote your ongoing webinar sessions and seminars with event information or speakers.",
          image: "/webinar-tile-preview.png",
          path: `/${IMAGE_GENERATOR}/Webinar-Seminars`,
          template: "Webinar-Seminars",
          new: false,
        },
        {
          category: "Social",
          cardTitle: "Campaign Template",
          cardContent:
            "Create global campaign creatives across a number of regions and languages.",
          image: "/global/campaign-tile-preview.jpg",
          path: `/${IMAGE_GENERATOR}/Global-Campaigns`,
          template: "Global-Campaigns",
          new: false,
        },
        {
          category: "Social",
          cardTitle: "Market Movers",
          cardContent: "React to key market price moves as they happen.",
          image: "/920x613-tile-market-movers.jpg",
          path: `/${IMAGE_GENERATOR}/Market-Movers`,
          template: "",
          new: false,
        },
      ],
    },
  ];
  return tabItems;
};
