import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAVOau2xy8V5aIvKU8k5IrrGVq2tksyXpA",
  authDomain: "marketing-tools-showcase.firebaseapp.com",
  databaseURL: "https://marketing-tools-showcase-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "marketing-tools-showcase",
  storageBucket: "marketing-tools-showcase.appspot.com",
  messagingSenderId: "473745428621",
  appId: "1:473745428621:web:77e8eae8fc6c84b72992c4",
  measurementId: "G-VW1Z961CK8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const analytics = getAnalytics(app);
var database = getDatabase(app);

const firebaseInit = { analytics, storage, database };

export default firebaseInit;
