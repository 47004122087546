import { ref, remove, serverTimestamp, set, update } from "firebase/database";
import { deleteObject, ref as sRef, uploadBytes } from "firebase/storage";
import firebaseInit from "../../firebase";
import {
  DebPath,
  SetPathToFile,
  StoragePath,
} from "../../utilities/fileUploader/pathHandlers";
import axios from "axios";
import Cookies from "js-cookie";
import { RemoveFileExt } from "../../utilities/utilityService";
import { C_GENERAL, C_LEGAL_DOCUMENTS } from "../../constants/types";

const database = firebaseInit.database;
const storage = firebaseInit.storage;

export const checkRef = (database, path) => {
  try {
    ref(database, path);
    if (/%/g.test(path)) {
      throw new Error("filename can't contain '%'");
    }
    return true;
  } catch (error) {
    if (error.message.includes("path argument was an invalid path")) {
      return "filename must be non-empty strings and can't contain '.', '#', '$', '[', or ']'";
    } else {
      return error.message;
    }
  }
};

//write data to rtdb
export const WriteUserData = (
  fileName,
  category,
  userId,
  setIsLoading,
  setResponse,
  formRef,
  legalCategory,
  handleApi,
  selectedFile,
  setFileName,
  setSelectedFile,
  setError
) => {
  var parse = RemoveFileExt(fileName);
  let path = DebPath(category, legalCategory, parse);
  let filePath = SetPathToFile(category, legalCategory, parse, fileName);

  if (checkRef(database, path) === true) {
    set(ref(database, path), {
      user_id: userId,
      file_name: fileName,
      category: category,
      file: filePath,
      createdAt: serverTimestamp(),
    })
      .then(() => {
        if (category === C_GENERAL || category === C_LEGAL_DOCUMENTS) {
          update(ref(database, `category/${category}`), {
            createdAt: serverTimestamp(),
          });
        }
        setIsLoading(false);
        console.log("Data saved successfully!");
        //upload file to storage
        UploadToStorage(
          fileName,
          selectedFile,
          category,
          legalCategory,
          parse,
          setFileName,
          setSelectedFile
        );
        setResponse(true);
        formRef.current.reset();
        handleApi(true);
        return true;
      })
      .catch((error) => {
        setIsLoading(false);
        setResponse(false);
        console.log("The write failed...", error);
      });
  } else {
    setIsLoading(false);
    setResponse(false);
    setError(checkRef(database, path));
  }
};

//upload file to storage
export const UploadToStorage = (
  fileName,
  selectedFile,
  category,
  legalCategory,
  parse,
  setFileName,
  setSelectedFile
) => {
  let filePath = StoragePath(category, legalCategory, parse, fileName);

  uploadBytes(sRef(storage, filePath), selectedFile)
    .then(() => {
      console.log("Uploaded successfully!");
      setFileName("");
      setSelectedFile(null);
    })
    .catch((error) => {
      console.log("Upload failed...");
    });
};

export const Delete = (value, legalCategory, handleApi) => {
  var parse = RemoveFileExt(value.file_name);
  let path = DebPath(value.category, legalCategory, parse);
  let file = StoragePath(value.category, legalCategory, parse, value.file_name);

  remove(ref(database, path))
    .then(() => {
      handleApi(true);
      deleteObject(sRef(storage, file))
        .then(() => {
          console.log("removed successfully - storage");
        })
        .catch((error) => {
          console.error(error);
        });
      console.log("removed successfully - db");
    })
    .catch((error) => {
      console.error(error);
    });
};

export const GetUploads = async () => {
  return await axios
    .get(
      `https://marketing-tools-showcase-default-rtdb.asia-southeast1.firebasedatabase.app/category.json?auth=${Cookies.get(
        "token"
      )}`
    )
    .then((res) => {
      if (res.data) {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
