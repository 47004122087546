import { C_GENERAL, C_LEGAL_DOCUMENTS } from "../../constants/types";

export const DebPath = (category, subCategory, dir) => {
  let path = "";
  if (category === C_LEGAL_DOCUMENTS) {
    path = `category/${category}/${subCategory}/${dir}_dir/`;
  } else if (category === C_GENERAL) {
    path = `category/${category}/${dir}_dir/`;
  } else {
    path = `category/${category}/`;
  }

  return path;
};

export const StoragePath = (category, subCategory, dir, fileName) => {
  let path = "";
  if (category === C_LEGAL_DOCUMENTS) {
    path = `${category}/${subCategory}/${dir}_dir/${fileName}`;
  } else if (category === C_GENERAL) {
    path = `${category}/${dir}_dir/${fileName}`;
  } else {
    path = `${category}/${fileName}`;
  }

  return path;
};

export const SetPathToFile = (category, subCategory, dir, fileName) => {
  let filePath = "";
  if (category === C_LEGAL_DOCUMENTS) {
    filePath = `https://firebasestorage.googleapis.com/v0/b/marketing-tools-showcase.appspot.com/o/${category}%2F${subCategory}%2F${dir}_dir%2F${fileName}?alt=media`;
  } else if (category === C_GENERAL) {
    filePath = `https://firebasestorage.googleapis.com/v0/b/marketing-tools-showcase.appspot.com/o/${category}%2F${dir}_dir%2F${fileName}?alt=media`;
  } else {
    filePath = `https://firebasestorage.googleapis.com/v0/b/marketing-tools-showcase.appspot.com/o/${category}%2F${fileName}?alt=media`;
  }
  return filePath;
};